import { CartStorage } from '../common/cart_storage';
import { fetch as fetchPolyfill } from 'whatwg-fetch';
import { modalAlert } from '../common/toastify';
import { priceFormat } from '../common/price_format';
//import set = Reflect.set;

const cart = new CartStorage();
if (document.querySelector('.c-container--additional-options-create, .additional-options')) {
  cart.cartKey = CartStorage.ADDITIONAL_OPTION_CART_KEY;
}
const commaFormatter = new Intl.NumberFormat('ja');

const initializeOptions = () => {
  const options = cart.getOrderOptions();
  for (let i = 0; i < options.length; i++) {
    const optionElement = <HTMLInputElement>document.getElementById(`option_value_${options[i]}`);
    if (optionElement) {
      optionElement.checked = true;

      const keyLabelId = optionElement.dataset.key;
      if (keyLabelId) {
        const accordionElement = <HTMLElement>document.getElementById(keyLabelId);
        if (accordionElement) {
          const accordionCheckId = accordionElement.getAttribute('for');
          if (accordionCheckId) {
            const accordionCheckElement = <HTMLInputElement>document.getElementById(accordionCheckId);
            if (accordionCheckElement) {
              accordionCheckElement.checked = true;
            }
          }

          accordionElement.classList.add('is-active');
          const target = accordionElement.dataset.target;
          if (target) {
            const targetElement = document.getElementById(target);
            if (targetElement) {
              targetElement.classList.add('is-active');
            }
          }
        }
      }
    }
  }
};

const renderCart = async (isOnlyPrice = false) => {
  if (cart.hasProducts()) {
    document.querySelector('.js-cart-list-no-item')?.setAttribute('style', 'display:none;');
  } else {
    document.querySelector('.js-cart-list-no-item')?.setAttribute('style', 'display:block;');
  }
  if (cart.hasOrderOptions()) {
    document.querySelector('.js-no-options')?.setAttribute('style', 'display:none;');
  } else {
    document.querySelector('.js-options-table')?.setAttribute('style', 'display:block;');
  }

  try {
    const response = await fetchPolyfill(`/cart/products.json${cart.toQueryString()}`);
    const json = await response.json();
    const orderOptionIds: string[] = cart.getOrderOptions();

    if (orderOptionIds.length > 0) {
      visibleOptionIds(orderOptionIds);
    }

    if (!isOnlyPrice) {
      renderCartProducts(json.products);
      renderOrderOptions(json.options);
    }
    renderPrices(json.prices, json.campaigns);

    const toShoppingButtons = document.querySelectorAll<HTMLInputElement>('.js-to-shopping');

    if (isOnlyPrice) {
      if (!cart.isShowCart()) {
        toShoppingButtons.forEach((button) => {
          button.disabled = true;
        });
      }
    } else {
      if (!cart.hasProducts()) {
        toShoppingButtons.forEach((button) => {
          button.disabled = true;
        });
      }
    }
  } catch (error) {
    console.error(error);
    modalAlert(
      'カート情報を取得することができませんでした。お手数ですが、カートページの再表示をお願いいたします。',
      true
    );
  }
};

const visibleOptionIds = (ids: string[]) => {
  ids.forEach((id: string) => {
    const optionValueInput = <HTMLInputElement>document.getElementById(`option_value_${id}`);
    if (optionValueInput) {
      optionValueInput.checked = true;
      const select = <HTMLElement>optionValueInput.closest('.order_option_select');
      if (select) {
        select.style.display = 'block';
      }
    }
  });
};

const addCampaign = async (code: string) => {
  clearError();

  if (!document.getElementById('coupon_list')) {
    return;
  }

  try {
    const response = await fetchPolyfill(`/cart/campaign.json?code=${code}`);
    const json = await response.json();

    if (json?.campaign) {
      renderCouponCampaign(json?.campaign);

      const campaignCode = <HTMLInputElement>document.getElementById('campaign_code');
      if (campaignCode) {
        campaignCode.value = '';
      }
      modalAlert(`${json?.campaign?.label}をカートに追加しました`);
    } else {
      renderError(['キャンペーンを取得することができませんでした。キャンペーンコードをご確認ください。']);
      modalAlert('エラーが発生しました。', true);
    }
  } catch (error) {
    console.error(error);
    renderError([
      'キャンペーンを取得することができませんでした。お手数ですが、再度の「反映」ボタンのクリックしてください。'
    ]);
    modalAlert('エラーが発生しました。', true);
  }
};

const validCampaign = async (targetElement: HTMLInputElement): Promise<boolean> => {
  clearError();

  try {
    const campaignId = targetElement.value;
    const addOrRemoveParams: string = targetElement.checked
      ? `add_campaign_id=${campaignId}`
      : `remove_campaign_id=${campaignId}`;
    let queryString = cart.toQueryString();
    if (queryString) {
      queryString = `${queryString}&${addOrRemoveParams}`;
    } else {
      queryString = `?${addOrRemoveParams}`;
    }
    const response = await fetchPolyfill(`/cart/valid_campaign.json${queryString}`);
    const json = await response.json();
    const toShoppingButtons = document.querySelectorAll<HTMLInputElement>('.js-to-shopping');

    if (json?.errors && json?.errors.length > 0) {
      renderError(json?.errors);
      toShoppingButtons.forEach((button) => {
        button.disabled = true;
      });
      modalAlert('エラーが発生しました。', true);
      return false;
    } else {
      toShoppingButtons.forEach((button) => {
        button.disabled = false;
      });
      changeOptionsAndCampaign();
      return true;
    }
  } catch (error) {
    console.error(error);
    toShoppingButtons.forEach((button) => {
      button.disabled = true;
    });
    modalAlert('エラーが発生しました。', true);
    return false;
  }
};

const updateCouponInputState = () => {
  const couponList = document.getElementById('coupon_list');
  const campaignCode = <HTMLInputElement>document.getElementById('campaign_code');
  const reflectButton = <HTMLButtonElement>document.querySelector('.js-reflect-campaign');
  const warningText = <HTMLParagraphElement | null>document.querySelector('.js-coupon-warning');

  if (!couponList || !campaignCode || !reflectButton) return;

  const hasCheckedCoupon = couponList.querySelector('input[type="checkbox"]:checked');

  if (hasCheckedCoupon) {
    campaignCode.disabled = true;
    reflectButton.disabled = true;
    if (!warningText) {
      const warning = document.createElement('p');
      warning.className = 'u-color-warning js-coupon-warning';
      warning.textContent = '変更する場合は、適用中のクーポンのチェックを外してください';
      couponList.parentElement?.appendChild(warning);
    }
  } else {
    campaignCode.disabled = false;
    reflectButton.disabled = false;
    warningText?.remove();
  }
};

const renderCouponCampaign = (campaign) => {
  const couponList = <HTMLElement>document.getElementById('coupon_list');
  const itemElement = getTemplateElement('coupon_item');
  if (!itemElement || !couponList) {
    return;
  }

  const campaignId = `campaign_${campaign.id}`;
  const input = <HTMLInputElement>itemElement.querySelector('.js-campaign-input');
  if (input) {
    input.dataset.code = campaign.coupon_code;
    input.setAttribute('id', campaignId);
    input.value = campaign.id;
    input.addEventListener('change', async (e) => {
      const valid = await campaignChangeListener(e);
      const target = e.target as HTMLInputElement;
      const labels = target?.labels;
      if (labels && labels.length > 0 && valid) {
        if (target.checked) {
          modalAlert(`${labels[0].textContent}をカートに追加しました`);
        } else {
          modalAlert(`${labels[0].textContent}をカートから削除しました`);
        }
      }
      updateCouponInputState();
    });

    input.checked = true;
    input.dispatchEvent(new Event('change'));
  }

  const label = <HTMLLabelElement>itemElement.querySelector('.js-campaign-label');
  if (label) {
    label.setAttribute('for', campaignId);
    label.textContent = campaign.label;
  }

  couponList.innerHTML = '';
  couponList.appendChild(itemElement);
};

const renderCartProducts = (products) => {
  if (products == null) {
    return;
  }

  const fragment = document.createDocumentFragment();

  for (let i = 0; i < products.length; i++) {
    const element = createItemElement(products[i]);
    if (element) {
      fragment.appendChild(element);
    }
  }

  const cartItems = document.querySelector('.js-cart-list');
  if (cartItems) {
    cartItems.textContent = null;
    cartItems.appendChild(fragment);
  }
};

type CartOption = Record<string, string>;
const renderOrderOptions = (options: CartOption[]) => {
  const tmpl = getTemplateElement('cart_option');
  if (!tmpl) {
    return;
  }
  const fragment = document.createDocumentFragment();
  options.forEach((option) => {
    const tmpl = getTemplateElement('cart_option');
    if (!tmpl) {
      return;
    }
    setTextContent(tmpl.querySelector('.js-option-key-label'), option.option_key_label);
    setTextContent(tmpl.querySelector('.js-option-value-label'), option.option_value_label);
    setInnerHtml(
      tmpl.querySelector('.js-option-value-price'),
      `${priceFormat(Number(option.price))}<small>円（税込${priceFormat(Number(option.price_include_tax))}円）</small>`
    );
    fragment.appendChild(tmpl);
  });
  const optionsTable = document.querySelector('.js-options-table');
  if (!optionsTable) {
    return;
  }
  optionsTable.innerHTML = '';
  optionsTable.appendChild(fragment);
  if (options.length > 0) {
    optionsTable.setAttribute('style', 'display:table-row-group;');
  } else {
    optionsTable.setAttribute('style', 'display:none;');
  }
};

const getTemplateElement = (id): DocumentFragment | null => {
  const content = (<HTMLTemplateElement>document.getElementById(id))?.content;
  if (!content) {
    return null;
  }

  return document.importNode(content, true);
};

const clearError = () => {
  document.querySelector('.js-error')?.setAttribute('style', 'display:none;');
  const errorList = document.querySelector('.js-error-list');
  if (errorList) {
    errorList.textContent = null;
  }
};

const renderError = (errors: string[]) => {
  document.querySelector('.js-error')?.setAttribute('style', 'display:block;');

  const errorList = <HTMLElement>document.querySelector('.js-error-list');
  if (errorList) {
    errorList.textContent = null;
    errors.forEach((error) => {
      const errorMessage = document.createElement('li');
      errorMessage.textContent = error;
      errorList.appendChild(errorMessage);
    });
  }
};

const createItemElement = (product): DocumentFragment | null => {
  const itemElement = getTemplateElement('cart_item');
  if (!itemElement) {
    return null;
  }

  const thumbnailImageElement = <HTMLImageElement>itemElement.querySelector('.js-product-thumbnail');
  if (thumbnailImageElement) {
    thumbnailImageElement.src = product.thumbnail;
    thumbnailImageElement.alt = product.title;
  }

  setTextContent(itemElement.querySelector('.js-product-title'), product.title);
  setTextContent(itemElement.querySelector('.js-product-title-kana'), product.title_kana);
  setTextContent(itemElement.querySelector('.js-product-category'), product.category);
  setInnerHtml(
    itemElement.querySelector('.js-product-options'),
    [
      product.product_classes.map((v) => `${v.key}:${v.label}`).join('<br>'),
      product.options.map((o) => `${o.key}:${o.label}`).join('<br>')
    ]
      .filter((o) => o)
      .join('<br>')
  );
  setInnerHtml(
    itemElement.querySelector('.js-product-price'),
    `${priceFormat(product.product_price_total)}<small>円（税込${priceFormat(
      product.product_price_total_include_tax
    )}円）</small>`
  );

  const deleteAnchorElement = <HTMLAnchorElement>itemElement.querySelector('.p-cart__delete');
  if (deleteAnchorElement) {
    deleteAnchorElement.dataset.key = product.key;
    deleteAnchorElement.addEventListener('click', (e) => {
      e.preventDefault();
      const anchorElement = <HTMLAnchorElement>e.target;
      cart.remove(anchorElement?.dataset.key);
      validCampaigns();
      renderCart();
    });
  }

  return itemElement;
};

const validCampaigns = async (): Promise<boolean> => {
  const campaigns = document.getElementById('campaign_list')?.querySelectorAll('input[type=checkbox]') ?? [];
  const promises: Array<Promise<boolean>> = [];
  campaigns.forEach((campaign) => {
    promises.push(validCampaign(campaign));
  });

  const coupons = document.querySelector('.js-coupon')?.querySelectorAll('input[type=checkbox]') ?? [];
  coupons.forEach((coupon) => {
    promises.push(validCampaign(coupon));
  });
  const result = await Promise.all(promises);
  return result.every((r) => r);
};

const renderPrices = (prices, campaigns) => {
  if (prices == null) {
    return;
  }

  setTextContent(document.querySelector('.js-subtotal-product-price'), `${priceFormat(prices.product_price)}円`);

  setTextContent(document.querySelector('.js-subtotal-option-price'), `${priceFormat(prices.option_price)}円`);

  setTextContent(document.querySelector('.js-products-sub-total'), `${priceFormat(prices.sub_total)}円`);
  const setDiscountContainer = document.querySelector('.js-set-discount-container');
  if (prices.set_discount < 0) {
    if (setDiscountContainer) {
      setDiscountContainer.setAttribute('style', 'display:table-row');
      setTextContent(document.querySelector('.js-set-discount'), `${commaFormatter.format(prices.set_discount)}円`);
    }
  } else {
    if (setDiscountContainer) {
      setDiscountContainer.setAttribute('style', 'display:none');
      setTextContent(document.querySelector('.js-set-discount'), `${commaFormatter.format(prices.set_discount)}円`);
    }
  }
  const campaignDiscountContainer = document.querySelector('.js-campaign-discount-container');
  if (prices.campaign_discount < 0) {
    if (campaignDiscountContainer) {
      campaignDiscountContainer.setAttribute('style', 'display:table-row');
      setTextContent(
        document.querySelector('.js-campaign-discount'),
        `${commaFormatter.format(prices.campaign_discount)}円`
      );
    }
  } else {
    if (campaignDiscountContainer) {
      campaignDiscountContainer.setAttribute('style', 'display:none');
      setTextContent(
        document.querySelector('.js-campaign-discount'),
        `${commaFormatter.format(prices.campaign_discount)}円`
      );
    }
  }
  setTextContent(document.querySelector('.js-tax'), `${priceFormat(prices.tax)}円`);
  setTextContent(document.querySelector('.js-shipping-fee'), `${priceFormat(prices.shipping_fee)}円`);
  setTextContent(document.querySelector('.js-total'), priceFormat(prices.total));

  const campaignContainer = document.querySelector('.js-campaign-items-container');
  if (campaignContainer) {
    if (campaigns && campaigns.length > 0) {
      campaignContainer.setAttribute('style', 'display:table-row;');
      const campaignItems = document.querySelector('.js-campaign-items');
      if (campaignItems) {
        campaignItems.innerHTML = campaigns.map((campaign) => `・${campaign.label}`).join('<br>');
      }
    } else {
      campaignContainer.setAttribute('style', 'display:none;');
    }
  }
};

const setTextContent = (element, content) => {
  if (element) {
    element.textContent = content;
  }
};

const setInnerHtml = (element, content) => {
  if (element) {
    element.innerHTML = content;
  }
};

const clearChecked = (radioParentElement: HTMLElement) => {
  if (radioParentElement.classList.contains('is-active')) {
    return;
  }

  const radios = radioParentElement.querySelectorAll('input') ?? [];
  for (let i = 0; i < radios.length; i++) {
    (<HTMLInputElement>radios[i]).checked = false;
  }
  changeOptionsAndCampaign();
  validCampaigns();
};

const checkFirstRadio = async (radioParentElement: HTMLElement) => {
  const radios = radioParentElement.querySelectorAll('input') ?? [];
  const radio = <HTMLInputElement>radios[0];
  radio.checked = true;
  changeOptionsAndCampaign();
  const valid = await validCampaigns();
  if (valid) {
    modalAlert(`${radio.dataset.label}をカートに追加しました`);
  }
};

const addOptionChangeListener = (parentElement: Element) => {
  const radios = parentElement.querySelectorAll('input') ?? [];
  for (let i = 0; i < radios.length; i++) {
    radios[i].addEventListener('change', async (e) => {
      changeOptionsAndCampaign();
      const valid = await validCampaigns();
      const target = e.target;
      if (target && valid) {
        modalAlert(`${(target as HTMLInputElement).dataset.label}をカートに追加しました`);
      }
    });
  }
};

const addCampaignChangeListener = (parentElement: Element) => {
  const checkedCampaigns = cart.getCampaigns();
  const radios = parentElement.querySelectorAll('input') ?? [];
  radios.forEach((radio) => {
    radio.checked = checkedCampaigns.includes(radio.value);
    radio.addEventListener('change', async (e) => {
      const valid = await campaignChangeListener(e);
      const target = e.target as HTMLInputElement;
      const labels = target?.labels;
      if (labels && labels.length > 0 && valid) {
        if (target.checked) {
          modalAlert(`${labels[0].textContent}をカートに追加しました`);
        } else {
          modalAlert(`${labels[0].textContent}をカートから削除しました`);
        }
      }
    });
  });
};

const campaignChangeListener = async (e: Event): Promise<boolean> => {
  const targetElement = <HTMLInputElement>e.target;
  if (targetElement) {
    return await validCampaign(targetElement);
  }
  return false;
};

const changeOptionsAndCampaign = () => {
  const radios = document.querySelector('.js-options')?.querySelectorAll('input[type=radio]') ?? [];
  const selectedOptions: string[] = [];
  for (let i = 0; i < radios.length; i++) {
    const radio = <HTMLInputElement>radios[i];
    if (radio.checked) {
      selectedOptions.push(radio.value);
    }
  }
  cart.changeOrderOption(selectedOptions);

  const checks = document.querySelector('.js-campaigns')?.querySelectorAll('input[type=checkbox]') ?? [];
  const selectedCampaign: string[] = [];
  for (let i = 0; i < checks.length; i++) {
    const check = <HTMLInputElement>checks[i];
    if (check.checked) {
      selectedCampaign.push(check.value);
    }
  }

  cart.clearCampaignCode();
  const couponChecks = document.querySelector('.js-coupon')?.querySelectorAll('input[type=checkbox]') ?? [];
  for (let i = 0; i < couponChecks.length; i++) {
    const couponCheck = <HTMLInputElement>couponChecks[i];
    if (couponCheck.checked) {
      selectedCampaign.push(couponCheck.value);
      if (couponCheck.dataset.code) {
        cart.setCampaignCode(couponCheck.dataset.code);
      }
    }
  }

  cart.changeOrderCampaign(selectedCampaign);

  renderCart(true);
};

const addAccordionChangeListener = (accordion: HTMLInputElement | undefined) => {
  if (!accordion) {
    return;
  }
  accordion.addEventListener('change', (e) => {
    const input = <HTMLInputElement>e.target;
    const label = input?.closest('.c-checkbox')?.querySelector<HTMLLabelElement>('.js-cart-accordion');
    if (!input || !label) {
      return;
    }
    label.classList.toggle('is-active');
    const target = label.dataset.target;
    const isActive: boolean = input.checked;

    if (target) {
      const targetElement = document.getElementById(target);
      if (targetElement) {
        if (isActive) {
          targetElement.classList.add('is-active');
          checkFirstRadio(targetElement);
        } else {
          targetElement.classList.remove('is-active');
          clearChecked(targetElement);
        }
      }
    }
  });
};

const addReflectCampaignCodeClickListener = () => {
  document.querySelector('.js-reflect-campaign')?.addEventListener('click', () => {
    const campaignCode = (<HTMLInputElement>document.getElementById('campaign_code'))?.value;
    addCampaign(campaignCode);
  });
};

document.querySelector('#js-storage-carts')?.setAttribute('value', JSON.stringify(cart.load()));
const additionalOptionContainer = document.querySelector('.c-container--additional-options-create');
if (!cart.hasProducts() && !additionalOptionContainer) {
  const shoppingSubmit = <HTMLInputElement>document.querySelector('.js-shopping-submit');
  if (shoppingSubmit) {
    modalAlert('カートに商品が入っていません。', true);
    shoppingSubmit.disabled = true;
  }
}

const toShoppingButtons = document.querySelectorAll<HTMLInputElement>('.js-to-shopping');
if (toShoppingButtons.length > 0) {
  toShoppingButtons.forEach((button) => {
    button.addEventListener('click', () => {
      if (button.dataset.link) {
        location.href = button.dataset.link + '?carts=' + JSON.stringify(cart.load());
      }
    });
  });
}

addReflectCampaignCodeClickListener();

// ページ読み込み時のクーポン入力フォームの状態を設定
updateCouponInputState();

const accordions = document.querySelectorAll('.js-cart-accordion');
const accordionInputs = document.querySelectorAll<HTMLInputElement>('.js-cart-accordion-input');
for (let i = 0; i < accordions.length; i++) {
  const accordion = <HTMLInputElement>accordions[i];
  addAccordionChangeListener(accordionInputs[i]);
  const target = accordion.dataset.target;
  if (target) {
    const radioParent = document.getElementById(target);
    if (radioParent) {
      addOptionChangeListener(radioParent);
    }
  }
}

const campaigns = document.getElementById('campaign_list');
if (campaigns) {
  addCampaignChangeListener(campaigns);
  const campaignCode = cart.getCampaignCode();
  if (campaignCode) {
    addCampaign(campaignCode);
  }
}

initializeOptions();

if (cart.isShowCart()) {
  document.querySelector('.js-cart')?.setAttribute('style', 'display:block;');
  document.querySelector('.js-cart-no-item')?.setAttribute('style', 'display:none;');
  if (cart.hasProducts()) {
    document.querySelector('.js-cart-list-no-item')?.setAttribute('style', 'display:none;');
  } else {
    document.querySelector('.js-cart-list-no-item')?.setAttribute('style', 'display:block;');
  }
} else {
  document.querySelector('.js-cart')?.setAttribute('style', 'display:none;');
  document.querySelector('.js-cart-no-item')?.setAttribute('style', 'display:block;');
}

// 初回読み込みは .js-cart-listが必要
if (document.querySelector('.js-cart-list')) {
  renderCart();
}
